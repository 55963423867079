import React from 'react'
import Helmet from 'react-helmet'
import EmailList from '../components/emails'

import Airtable from 'airtable'
import Downshift from 'downshift'
import { navigate } from 'gatsby'

import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

export default () => {
  const [inputValue, setInputValue] = React.useState('')
  const [emailVerification, setEmailVerification] = React.useState('')
  const [validEmail, setValidEmail] = React.useState(true)
  const [selectedItem, setSelectedItem] = React.useState({})
  const [submitted, setSubmitted] = React.useState(false)
  const [values, setValues] = React.useState({
    Name: '',
    Email: '',
    RSVP: '',
    Company: '',
    Title: '',
    'Plus 1': '',
    'Plus 1 - Name': '',
    'Plus 1 - Email': '',
    'Plus 1 - Title': '',
  })

  const base = new Airtable({ apiKey: 'keyorulszEpkFDsYO' }).base(
    'app1hKxGZKMAUAacW'
  )

  const _handleChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }

  // Get Records
  const _retriveRecords = () => {
    base('Media RSVP 2019')
      .select({
        view: 'Grid view',
      })
      .firstPage(function(err, records) {
        if (err) {
          console.error(err)
          return
        }
        records.forEach(function(record) {
          console.log('Retrieved', record.get('Email'))
        })
      })
  }

  function _getEmail(email) {
    const newEmail = EmailList.filter(data => {
      return email === data
    })
    setEmailVerification(newEmail.join())
  }

  // Create Records
  const _createRecord = () => {
    emailVerification.length > 0
      ? base('Media RSVP 2019').create({ ...values }, function(err, record) {
          if (err) {
            console.error(err)
            return
          } else {
            setSubmitted(true)
          }
          setValues({
            Name: '',
            Email: '',
            RSVP: '',
            Company: '',
            Title: '',
            'Plus 1': '',
            'Plus 1 - Name': '',
            'Plus 1 - Email': '',
            'Plus 1 - Title': '',
          })
        })
      : setValidEmail(false)
  }

  return (
    <Layout>
      <SEO title="R&R Partners | Media Tailgate" />
      <Helmet
        meta={[
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, shrink-to-fit=no maximum-scale=1, user-scalable=0',
          },
        ]}
      />
      <div className="container content">
        <div className="logo">
          <img
            src={require('../images/logo-red.png')}
            alt="R and R Partners Red Logo"
            className="animated bounceIn"
          />
        </div>
        <hr />
        <h1 className="text--center animated fadeIn title">University</h1>
        <h4 className="text--center animated fadeIn subtitle">
          Where every day is game day.
        </h4>
        <form
          className={`form animated ${submitted ? 'zoomOut' : 'zoomIn'}`}
          noValidate
          style={{
            height: submitted ? 0 : '100%',
          }}
        >
          <TextField
            id="name"
            label="Name"
            value={values.name}
            onChange={e => _handleChange('Name', e.target.value)}
            margin="normal"
            fullWidth
            style={{
              marginBottom: 30,
            }}
            required
          />
          <TextField
            error={!validEmail}
            helperText={
              !validEmail
                ? 'Please enter an email address associated with our email list and try again'
                : ''
            }
            id="email"
            label="Email"
            value={values.email}
            onChange={e => _handleChange('Email', e.target.value)}
            onBlur={e => _getEmail(e.target.value)}
            margin="normal"
            fullWidth
            style={{
              marginBottom: 30,
            }}
            required
          />

          {/*==========*/}
          <FormControl
            component="fieldset"
            className="radio-buttons-group"
            style={{
              marginTop: 40,
            }}
            required
          >
            <FormLabel component="legend">RSVP</FormLabel>
            <RadioGroup
              aria-label="RSVP"
              name="rsvp"
              value={values.rsvp}
              onChange={e => _handleChange('RSVP', e.target.value)}
              required
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {/*==========*/}
          <TextField
            id="company"
            label="Company"
            value={values.company}
            onChange={e => _handleChange('Company', e.target.value)}
            margin="normal"
            fullWidth
          />
          {/*==========*/}
          <TextField
            id="title"
            label="Title"
            value={values.title}
            onChange={e => _handleChange('Title', e.target.value)}
            margin="normal"
            fullWidth
          />
          {/*==========*/}
          <FormControl
            component="fieldset"
            className="radio-buttons-group"
            style={{
              marginTop: 40,
            }}
            required
          >
            <FormLabel component="legend">Plus 1</FormLabel>
            <RadioGroup
              aria-label="plus-1"
              name="plus1"
              value={values.plus1}
              onChange={e => _handleChange('Plus 1', e.target.value)}
              required
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <div hidden={values['Plus 1'] !== 'Yes' ? true : false}>
            {/*==========*/}
            <TextField
              id="plus1name"
              label="Plus 1 - Name"
              value={values.plus_1_name}
              onChange={e => _handleChange('Plus 1 - Name', e.target.value)}
              margin="normal"
              fullWidth
            />
            {/*==========*/}
            <TextField
              id="plus1email"
              label="Plus 1 - Email"
              value={values.plus_1_email}
              onChange={e => _handleChange('Plus 1 - Email', e.target.value)}
              margin="normal"
              fullWidth
              type="email"
              autoComplete="email"
            />
            {/*==========*/}
            <TextField
              id="plus1title"
              label="Plus 1 - Title"
              value={values.plus_1_title}
              onChange={e => _handleChange('Plus 1 - Title', e.target.value)}
              margin="normal"
              fullWidth
            />
          </div>
          <Button
            onClick={_createRecord}
            size="large"
            variant="outlined"
            color="secondary"
            fullWidth
            className="form__submit-btn"
            style={{
              marginTop: 40,
              marginBottom: 40,
              fontFamily: 'Poppins',
              fontWeight: 900,
              fontSize: '1.5rem',
            }}
          >
            RSVP
          </Button>
        </form>
        <h1 className="text--center animated fadeIn title">
          R&R’S 14th ANNUAL MEDIA TAILGATE
        </h1>
        <div className="event">
          <div className="event--left">
            <p>Where</p>
          </div>
          <div className="event--right">
            <p>900 S. Pavilion Center Dr., Suite 100</p>
          </div>
          <div className="event--left">
            <p>when</p>
          </div>
          <div className="event--right">
            <p>
              Friday, September 13 @ 3 p.m. <br />
              Please RSVP by Friday, August 30
            </p>
          </div>
        </div>
        <p className="bottom-text">
          Wear your favorite college gear. It’s tailgate season.{' '}
        </p>
      </div>
    </Layout>
  )
}
