export default [
  'dane.aagaard@bleacherreport.com',
  'james.abe@oracle.com',
  'jack.abell@nbcuni.com',
  'tabril@entravision.com',
  'melanie@alldef.co',
  'magius@semcasting.com',
  'naikara@expediagroup.com',
  'marcus.albinder@vice.com',
  'alexis_aliquo@condenast.com',
  'ralvarez@kochava.com',
  'hal@matadornetwork.com',
  'jason.anthony@siriusxm.com',
  'asaehelp@gmail.com',
  'carmstrong@olympiacompanies.com',
  'tb@baakmedia.com',
  'avab@gocurb.com',
  'ucla02_gb@yahoo.com',
  'nicole.baran@cbs.com',
  'pbarnhill@obasa.com',
  'jason.berkowitz@cbsinteractive.com',
  'jbermudez@quatroglobal.com',
  'eric.bigotti@clearchannel.com',
  'sharonb2@suncitylv.com',
  'ablanar@ntmllc.com',
  'brian_blando@discovery.com',
  'cblomberg@expedia.com',
  'ross@matadornetwork.com',
  'eabraniff@mensvows.com',
  'scott.brannon@matadornetwork.com',
  'lbraue@tradeshowexecutive.com',
  'jonna@nevadabusiness.com',
  'connie@nevadabusiness.com',
  'dbriskin@lamag.com',
  'stuart.brockington@pridemedia.com',
  'crissie.bown@fox5vegas.com',
  'mbrown@captivate.com',
  'sophia.brown@sojern.com',
  'amy.bruzgo@oracle.com',
  'rich.bryant@adara.com',
  'a.buckingham@winnemuccapublishing.net',
  'brett.burke@coxmedia.com',
  'elaine.burns@entercom.com',
  'kelsey.burton@katz-media.com',
  'tricia@snapchat.com',
  'bobby.calero@groundtruth.com',
  'jcampbell@coxreps.com',
  'kimberly.caraig@fox.com',
  'ccardin@spotify.com',
  'renee.casteel@sojern.com',
  'carlo.castilla@lasvegaslightsfc.com',
  'kelly.cawood@summerlincouncil.com',
  'rc@rogue5media.com',
  'roman.charmes@nbcuni.com',
  'lisac@vectormedia.com',
  'annie.c.chen@abc.com',
  'stevey.clark@sojern.com',
  'pcoffey@sunset.com',
  'pierre.coleman@complex.com',
  'rachel.commeford@brookfieldpropertiesretail.com',
  'jcompton@lamar.com',
  'cheryl.congdon@mni.com',
  'ginny@twitter.com',
  'jessica@connelloutdoor.com',
  'gcontreras@surfacemedia.com',
  'ccoon@questex.com',
  'demetriusc@fb.com',
  'autumn@yieldmo.com',
  'acopes@spotx.tv',
  'frances@reaganusa.com',
  'bcossio@lamar.com',
  'patti.costello@acuityads.com',
  'acremo@expediagroup.com',
  'frank.dagnillo@outfrontmedia.com',
  'lindsay.davenport@bustle.com',
  'gary.davidson@espn.com',
  'lynn@sabiomobile.com',
  'day@weekendsherpa.com',
  'mdemlow@vectormedia.com',
  'rdenning@lasvegasnow.com',
  'jdesimone@lamar.com',
  'sdiamond@lasvegasnow.com',
  'meredith.dillon@sojern.com',
  'carey.a.dixon@abc.com',
  'molliedoherty@placeiq.com',
  'mdoherty@placeiq.com',
  'jennifer.dolan@brookfieldpropertiesretail.com',
  'bdolby@vegasgoldenknights.com',
  'lvspiritmedia@yahoo.com',
  'luis@grammy.com',
  'jdrummond@obasa.com',
  'mdunklee@obasa.com',
  'rdusseau@mensvows.com',
  'breana@pioneercenter.com',
  'staceyeisenberg@iheartmedia.com',
  'aelsmore@ntmllc.com',
  'zerickson@bombora.com',
  'aestrada@lamar.com',
  'ffeder@kvegas.com',
  'josh.feldman@cbs.com',
  'craig@trifektamediainc.com',
  'lfernandez@pandora.com',
  'lfischer@exhibitormagazine.com',
  'pfisher@modernluxury.com',
  'cfitzpatrick@yieldmo.com',
  'diona@highwayradio.com',
  'sforsythe@lamar.com',
  'cfrancisco@vegaspbs.org',
  'sharon@success-reps.com',
  'max@davidlv.com',
  'joanne@davidlv.com',
  'todd@lasvegasbillboards.com',
  'afrustaci@wasserman-partners.com',
  'courtney.fuhrmann@morris.com',
  'fulcinitif@opportunityvillage.org',
  'heidigable@iheart.com',
  'joelgable@iheartmedia.com',
  'mgallagher@fb.com',
  'justin@q.digital',
  'agelves@surfacemedia.com',
  'kgilbert@olympiacompanies.com',
  'stefanie.gilman@nytimes.com',
  'pgiudice@tripadvisor.com',
  'sgogna@fanatics.com',
  'eric.gonda@sojern.com',
  'heidig@vectormedia.com',
  'cody@samba.tv',
  'mgrant@lasvegasnow.com',
  'laurag@pinterest.com',
  'mgraves@reviewjournal.com',
  'sgreenfield@lamag.com',
  'sarah.grindle@amobee.com',
  'jordan@yieldmo.com',
  'renee@ivorystar.com',
  'jhadden@triplelift.com',
  'jeanie.haddox@howardhughes.com',
  'vhammel@cbs.com',
  'dan.hammel@flashtalking.com',
  'rhaniffa@pandora.com',
  'bill.hanley@global.com',
  'khayes@americantargetnetwork.com',
  'kyle@tvaccess.com',
  'casey.hendershott@kre-8media.com',
  'lihenderson@comcast.net',
  'steve.hicks@sprinklr.com',
  'dho1@pandora.com',
  'ben.holmes@adcolony.com',
  'Leonard.Holtz@nbcuni.com',
  'madi.hood@oracle.com',
  'kyle.hoover@groundtruth.com',
  'ahoppe@nevadamagazine.com',
  'rhoracek@reviewjournal.com',
  'lhoughton@yesco.com',
  'lhowfield@lasvegasnow.com',
  'katie.husa@bustle.com',
  'dhuseland@semcasting.com',
  'todd.hutton@coxmedia.com',
  'ashanti@alldef.co',
  'mjablon@clearstream.tv',
  'eric.james@pridemedia.com',
  'desiree.janda@adcolony.com',
  'janewaye@opportunityvillage.org',
  'rjennings@iaee.com',
  'cjohnson@aviatorslv.com',
  'mjohnson@mediamaxnetwork.com',
  'kate@myvegasmag.com',
  'tyler.jones@christal-radio.com',
  'pjoseph@8newsnow.com',
  'eric.josten@natgeo.com',
  'nikki.kaneshiro@snapchat.com',
  'lee.karchawer@emodoinc.com',
  'dan.kearney@entercom.com',
  'kelly.kelleher@nbcuni.com',
  'kellogg@factual.com',
  'ckelly@kayak.com',
  'akelly@ntmllc.com',
  'ckenefick@surfacemedia.com',
  'garok@thinknear.com',
  'michelle.kim@hulu.com',
  'tkim@tripadvisor.com',
  'mkim@tremorvideodsp.com',
  'allen@pinterest.com',
  'timothy.kittrick@oracle.com',
  'erikaklafehn@clearchannel.com',
  'kelsey.knake@sojern.com',
  'alicia.knighton@coxmedia.com',
  'kristine.komorny@kre-8media.com',
  'michael.korr@kvvu.com',
  'brianna.kouretas@gmgvegas.com',
  'jkoury@google.com',
  'don@connelloutdoor.com',
  'Kris.Kruk@nbcuni.com',
  'bill@taxiadslasvegas.com',
  'doug.kushla@inpwrd.com',
  'ylakanwal@rhythmone.com',
  'slamonica@lamar.com',
  'ben.lasher@ncm.com',
  'lindsaylavietes@clearchannel.com',
  'elli_lee@spe.sony.com',
  'bleo@tripadvisor.com',
  'cleonard@twitter.com',
  'kim.lewis@bet.net',
  'ellen@lewisstafford.com',
  'cristine.lindholm@ktnv.com',
  'erika.loberg@samba.tv',
  'llowery@captivate.com',
  'marionl@robbreport.com',
  'samloya@lvradio.com',
  'dean.luplow@nbcuni.com',
  'vincentl@thinknear.com',
  'mary.macdonald@lvradio.com',
  'onnalee@afar.com',
  'smack18@bloomberg.net',
  'gina@wcbar.org',
  'cmadsen@sbgtv.com',
  'smajor@univisionradio.com',
  'tmaldonado@travalliance.com',
  'manciniw@dnb.com',
  'dawn.mangum@gmgvegas.com',
  'cmann@twitter.com',
  'dmarcou@travelzoo.com',
  'patrick.mark@nbcuni.com',
  'natalieo@lvradio.com',
  'samy@tastemade.com',
  'gina.massenzi@entercom.com',
  'kmatute@rxbags.com',
  'matzke@tastemade.com',
  'mmccarthy2@cbs.com',
  'mmcewen@nativo.com',
  'nell.mcgann@centro.net',
  'ashley.mcintyre@bbgi.com',
  'dmcnaughton@lamar.com',
  'rmercer@triplelift.com',
  'imeyer@univision.net',
  'cmiller@outdooradsolutions.com',
  'smiller@ttgmedia.com',
  'mmizera@triplelift.com',
  'jamesm@mypassionmedia.com',
  'smoore@undertone.com',
  'cuentas2@continentalmedia.com.mx',
  'Misty.Morgan@ktnv.com',
  'mamosby@expediagroup.com',
  'stewart.nacht@pridemedia.com',
  'dneel@thesmithcenter.com',
  'geetha@semcasting.com',
  'anna@gumgum.com',
  'kscherer@pandora.com',
  'jnoble@expedia.com',
  'betsy.novak@acuityads.com',
  'brian@isluxury.com',
  'colleen.obrien@hulu.com',
  'chris@biznv.com',
  'ty@gumgum.com',
  'ginao@newsreview.com',
  'kyle.olson@nbcuni.com',
  'jostrovsky@nevadajustice.org',
  'jamal.parker@gmgvegas.com',
  'tparks@trep.com',
  'whitney.pavlovich@ncm.com',
  'hpendleton@outdooradsolutions.com',
  'laura.peper@amobee.com',
  'maria@tvaccess.com',
  'patricia_perez@discovery.com',
  'lisakayperrin@gmail.com',
  'brett.peterson@netmining.com',
  'apetryshyn@lamar.com',
  'augiep@gocurb.com',
  'anphillips@linkedin.com',
  'frank.pica@adyoulike.com',
  'ginny@smartmeetings.com',
  'michaelprojansky@iheartmedia.com',
  'alison.posada@centro.net',
  'william.powers@foxtv.com',
  'andrew.prasad@dujour.com',
  'geoff@reaganusa.com',
  'christine@taxiadslasvegas.com',
  'jolene@taxiadslasvegas.com',
  'tony@taxiadslasvegas.com',
  'rpribble@outdooradsolutions.com',
  'nancyprine@clearchannel.com',
  'michaelprojansky@iheartmedia.com',
  'dpurdy@sharethrough.com',
  'alexandra@smartmeetings.com',
  'anne.quest@ncm.com',
  'dylan@factual.com',
  'elyssa.rammos@scacai.com',
  'jrand@pinterest.com',
  'nraynor@twitter.com',
  'peter.reisner@vice.com',
  'lreynolds@kayak.com',
  'mreynolds@ttgmedia.com',
  'lynne.richardson@meetingstoday.com',
  'bob.ridzak@allovermedia.com',
  'mike.rizzo@netmining.com',
  'lanice@power88lv.com',
  'prongavilla@sbgtv.com',
  'KRosenfeld@travelweekly.com',
  'jrosing@applelg.net',
  'whit@snap.com',
  'grusso@ntmllc.com',
  'msaavedra@nnbw.biz',
  'lscarbrough@theknotww.com',
  'mschepers@sharethrough.com',
  'steven.schiff@ktnv.com',
  'dseong@triplelift.com',
  'dsergeant@vegasgoldenknights.com',
  'shafrir@fb.com',
  'tinasharma@google.com',
  'dshatten@crosspixel.net',
  'ben.shear@nccmedia.com',
  'edwardsheftel@iheartmedia.com',
  'wendy.shelton@ktnv.com',
  'molly.sherer@sojern.com',
  'eshibata@twitter.com',
  'sarah.shostak@complex.com',
  'owen@captiv8.io',
  'giancarlo.silva@viacom.com',
  'MSimkin@pcma.org',
  'mskenandore@outdooradsolutions.com',
  'scott.slayton@nbcuni.com',
  'gina@gumgum.com',
  'garlin.smith@pluto.tv',
  'allasandrina.soffa@bet.net',
  'bspotleson@vegaspbs.org',
  'diane.srsen@katzradiogroup.com',
  'rstgermain@xogrp.com',
  'michael@lewisstafford.com',
  'sstallworth@unlvsportsproperties.com',
  'dfleming@viamediatv.com',
  'matthews@networldmediagroup.com',
  'marketing@luxuryestates.com',
  'progressiverancher@elko.net',
  'kelsey@pushspring.com',
  'nstreets@elkodaily.com',
  'kstroud@reviewjournal.com',
  'lstrumwasser@sbgtv.com',
  'ksupsic@cuebiq.com',
  'jswanner@unlvsportsproperties.com',
  'cswedelson@pinterest.com',
  'sszkolnik@bleacherreport.com',
  'dean@proexpolv.com',
  'nicole.taylor@snap.com',
  'michael.thill@centro.net',
  'claire.thompson@vice.com',
  'kieran.thompson@nbcuni.com',
  'ryan.thompson@turner.com',
  'wthompson@pvtimes.com',
  'tc.torres@coxmedia.com',
  'thanh.tran@flashtalking.com',
  'ctrares@lamar.com',
  'kim@nevadapublicradio.org',
  'cdalasvegas@gmail.com',
  'stacie.tursi@ncm.com',
  'nurlan.urazbaev@gmail.com',
  'kurbatchka@westwoodspot.com',
  'kenny.utler@howardhughes.com',
  'cvaghi@magazinec.com',
  'gloria.valencia@ktnv.com',
  'andy.vanaken@nbcuni.com',
  'markus@nevadapublicradio.com',
  'jvann@lamag.com',
  'joanna.verner@priceline.com',
  'avicino@xogrp.com',
  'jvillela@lamar.com',
  'jan.vimal@adyoulike.com',
  'christine.wagner@kvvu.com',
  'lisa.walker@ncm.com',
  'twalsh@lamar.com',
  'jaime.watkins@kre-8media.com',
  'jeremie.watkins@kre-8media.com',
  'anthony.watters@priceline.com',
  'steve.weiser@extratv.com',
  'johnhweller@comcast.net',
  'steele.weniger@outfrontmedia.com',
  'twebster@factual.com',
  'rachel.west@ncm.com',
  'brad.west@katzmedia.com',
  'davewestburg58@gmail.com',
  'brianwheeler@clearchannel.com',
  'cwhite1@pandora.com',
  'twickenhiser@sbgtv.com',
  'maryanne.williams@outfrontmedia.com',
  'twilliamson@suncityaliante.us',
  'rwimberly@tsnn.com',
  'rebecca.wisniakowski@hulu.com',
  'jeff.wityak@openslate.com',
  'ray.woodbeck@ncm.com',
  'jason.yasment@departures.com',
  'kyoung@nativo.com',
  'melissayoung@fb.com',
  'ayoung@travelzoo.com',
  'tzaccheo@roku.com',
  'tzamora@dstillery.com',
  'cmzanghi@sbgtv.com',
  'szieger@tv-one.tv',
  'bubbatika@yahoo.com',
  'bgasst@yahoo.com',
  'lou.dangeli@cirquedusoleil.com',
  'ninfa.valdez@cirquedusoleil.com',
  'drew.dicostanza@cirquedusoleil.com',
  'chris@davidcopperfield.com',
  'alan.walker@hakkasan.com',
  'swise@hakkasan.com',
  'sasharincon@livenation.com',
  'dennis.lafontaine@topgolf.com',
  'kim.chute@topgolf.com',
  'brittany.mclemore@topgolf.com',
  'reuben.taylor@topgolf.com',
  'scott.deangelo@allegiantair.com',
  'kim.downing@allegiantair.com',
  'justin.ralenkotter@allegiantair.com',
  'sullivan.charles@allegiantair.com',
  'chanteln.stevens@allegiantair.com',
  'kylee.ashby@allegiantair.com',
  'kimberly.schaefer@allegiantair.com',
  'asmith@nvenergy.com',
  'cchism@nvenergy.com',
  'jschuricht@nvenergy.com',
  'beth.moore@lvvwd.com',
  'diana.diaz@lvvwd.com',
  'nick.tulli@lvvwd.com',
  'bronson.mack@lvvwd.com',
  'woodm@opportunityvillage.org',
  'jgudai@adomni.com',
  'lgiglia@adomni.com',
  'kathy.makranyi@globality.com',
  'tanya.bhasin@globality.com',
  'erez.yereslove@globality.com',
  'angela.ciciriello@thed.com',
  'mdini@goldengatecasino.com',
  'eric@o-arms.com',
  'joshua.laroche@farmersinsurance.com',
  'stemme@xanterra.com',
  'borourke@xanterra.com',
  'mknetemann@xanterra.com',
  'mbruhn@pabst.com',
  'latkinson@pabst.com',
  'jmedcraft@pabst.com',
  'nreely@premiumpbc.com',
  'stephanie.lloyd@farmersinsurance.com',
  'michelle.dornfeld@farmersinsurance.com',
  'cory@jontaffer.com',
  'sean@jontaffer.com',
  'tgallegos@americafirst.com',
  'dfairall@americafirst.com',
  'zwenigar@americafirst.com',
  'heidigable@iheartmedia.com',
  'venus.hammel@entercom.com',
  'c.fuhrmann@jamesgelliott.com',
  'ericbigotti@clearchannel.com',
  'najja.pemberton@samba.tv',
  'skennedy@tripadvisor.com',
  'doug.smith@sojern.com',
  'jamie.watkins@kre-8media.com',
  'drew.dicostanzo@cirquedusoleil.com',
  'awalker@hakkasan.com',
  'zwinegar@americafirst.com',
  'ericka.aviles@lasvegaslightsfc.com',
  'steve.pastorino@lasvegaslightsfc.com',
  'chris.chapman@lasvegaslightsfc.com',
  'kailee.smith@lasvegaslightsfc.com',
  'brett.lashbrook@lasvegaslightsfc.com',
  'lisa.yoon.reynolds@abc.com',
  'jakahoshi@univision.net',
  'libby@o-arms.com',
  'franklin@o-arms.com',
  'troy@kargo.com',
  'danielle.bracamonte@blis.com',
  'riley@barstoolsports.com',
  'jack.gonzalez@barstoolsports.com',
  'chad.bowman@barstoolsports.com',
  'chelseah@blueman.com',
  'karen.saucedo@cirquedusoleil.com',
  'heather.burdette@cirquedusoleil.com',
  'freddy.marron@cirquedusoleil.com',
  'denae.beckett@cirquedusoleil.com',
  'nandita.marshall@cirquedusoleil.com',
  'jenelle.jacks@cirquedusoleil.com',
  'tanima@google.com',
  'heatherchoi@google.com',
  'elliott@innovid.com',
  'jbenhaim@tunein.com',
  'nwright@tunein.com',
  'erika.diprofio@seaworld.com',
  'natalie.sims@seaworld.com',
  'mitchel.wilson@seaworld.com',
  'randy_arichea@comcast.com',
  'timothy.murray@adara.com',
  'grant.palmer@adara.com',
]
